<template>
  <div class="statistical_box">
    <div class="base_info">
      <h3>一、基础信息</h3>
      <ul>
        <li v-for="(item,index) in this.firmInfo" :key="index">
          <p class="tt">{{index+1}}.{{ item.problem }}<span>[ {{ item.choice }} ]</span></p>
          <p v-if="!item.table && !item.product">
            <router-link :to="{path:'/suervey1',query:{type : index+1}}" target="_blank" class="more">查看详细信息</router-link> <!--传递参数后续接接口-->
          </p>
          <div v-else-if="item.table" class="main_table">
            <table class="align_c" border="0" cellspacing="0" cellpadding="0">
              <thead>
              <tr>
                <th class="align_l">选项</th>
                <th>小计</th>
                <th>占比</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item1,index) in item.table" :key="index">
                <td class="align_l">{{ item1.options }}</td>
                <td>{{ item1.subtotal }}</td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p :style="{ width: item1.percent }"></p>
                    </div>
                    <span>{{ item1.percent }}</span></div>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th class="align_l"><b>本题有效填写人次</b></th>
                <th>1086</th>
                <th></th>
              </tr>
              </tfoot>
            </table>
            <p class="perWay">
              <a href="javascript:" class="blue" @click="centerDialogVisible = true">查看多选题百分比计算方法 ></a>
            </p>
          </div>
          <div v-else-if="item.product" class="main_table">
            <table class="align_c" border="0" cellspacing="0" cellpadding="0">
              <thead>
              <tr>
                <th class="align_l">行标题</th>
                <th>小计</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="align_l">产品名称：{{ item.product.name }}</td>
                <td>
                  <a href="05analysis_detail.html" target="_blank" class="blue">查看详情</a>
                </td>
              </tr>
              <tr>
                <td class="align_l">供应区域：{{ item.product.region }}</td>
                <td>
                  <a href="05analysis_detail.html" target="_blank" class="blue">查看详情</a>
                </td>
              </tr>
              <tr>
                <td class="align_l">运输方式：{{ item.product.transport }}</td>
                <td>
                  <a href="05analysis_detail.html" target="_blank" class="blue">查看详情</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
<!--        <li>
          <p class="tt">2. 您的企业属于哪种企业类型？( 必填 ) <span>[ 单项选择 ]</span></p>
          <div class="main_table">
            <table class="align_c" border="0" cellspacing="0" cellpadding="0">
              <thead>
              <tr>
                <th class="align_l">选项</th>
                <th>小计</th>
                <th>占比</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="align_l">有限责任公司</td>
                <td>881</td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width:60%;"></p>
                    </div>
                    <span>60%</span></div>
                </td>
              </tr>
              <tr>
                <td class="align_l">有限责任公司</td>
                <td>881</td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width: 77.26%;"></p>
                    </div>
                    <span>77.26%</span></div>
                </td>
              </tr>
              <tr>
                <td class="align_l">有限责任公司</td>
                <td>881</td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width: 52.26%;"></p>
                    </div>
                    <span>52.26%</span></div>
                </td>
              </tr>
              <tr>
                <td class="align_l">有限责任公司</td>
                <td>881</td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width: 60%;"></p>
                    </div>
                    <span>60%</span></div>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th class="align_l"><b>本题有效填写人次</b></th>
                <th>1086</th>
                <th></th>
              </tr>
              </tfoot>
            </table>
          </div>
        </li>
        <li>
          <p class="tt">3. 企业核心人员获得资讯的方式有哪些？ <span>[ 多项选择 ]</span></p>
          <div class="main_table">
            <table class="align_c" border="0" cellspacing="0" cellpadding="0">
              <thead>
              <tr>
                <th class="align_l">选项</th>
                <th>小计</th>
                <th>占比</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="align_l">有限责任公司</td>
                <td>881</td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width:60%;"></p>
                    </div>
                    <span>60%</span></div>
                </td>
              </tr>
              <tr>
                <td class="align_l">有限责任公司</td>
                <td>881</td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width: 77.26%;"></p>
                    </div>
                    <span>77.26%</span></div>
                </td>
              </tr>
              <tr>
                <td class="align_l">有限责任公司</td>
                <td>881</td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width: 52.26%;"></p>
                    </div>
                    <span>52.26%</span></div>
                </td>
              </tr>
              <tr>
                <td class="align_l">有限责任公司</td>
                <td>881</td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width: 60%;"></p>
                    </div>
                    <span>60%</span></div>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th class="align_l"><b>本题有效填写人次</b></th>
                <th>1086</th>
                <th></th>
              </tr>
              </tfoot>
            </table>
            <p class="perWay">
              <a href="javascript:" class="blue" @click="centerDialogVisible = true">查看多选题百分比计算方法 ></a>
            </p>
          </div>
        </li>
        <li>
          <p class="tt">4. 您的企业主要销售的产品有哪些，请罗列三种并做简要描述？ <span>[ 多项填空 ]</span></p>
          <div class="main_table">
            <table class="align_c" border="0" cellspacing="0" cellpadding="0">
              <thead>
              <tr>
                <th class="align_l">行标题</th>
                <th>小计</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="align_l">产品名称：</td>
                <td>
                  <a href="05analysis_detail.html" target="_blank" class="blue">查看详情</a>
                </td>
              </tr>
              <tr>
                <td class="align_l">供应区域：</td>
                <td>
                  <a href="05analysis_detail.html" target="_blank" class="blue">查看详情</a>
                </td>
              </tr>
              <tr>
                <td class="align_l">运输方式：</td>
                <td>
                  <a href="05analysis_detail.html" target="_blank" class="blue">查看详情</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <p class="tt">第5题：您的企业是否在使用专业软件，如果有软件是哪一款？ <span>[ 单项填空 ]</span></p>
          <div class="main_table">
            <table class="align_c" border="0" cellspacing="0" cellpadding="0">
              <thead>
              <tr>
                <th class="align_l">选项</th>
                <th>小计</th>
                <th>占比</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="align_l">有使用</td>
                <td>
                  <a href="05analysis_detail.html" target="_blank" class="blue">查看详情</a>
                </td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width:60%;"></p>
                    </div>
                    <span>60%</span></div>
                </td>
              </tr>
              <tr>
                <td class="align_l">有限责任公司</td>
                <td>
                  <a href="05analysis_detail.html" target="_blank" class="blue">查看详情</a>
                </td>
                <td>
                  <div class="statu">
                    <div class="bar">
                      <p style="width: 77.26%;"></p>
                    </div>
                    <span>77.26%</span></div>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th class="align_l"><b>本题有效填写人次</b></th>
                <th>1086</th>
                <th></th>
              </tr>
              </tfoot>
            </table>
          </div>
        </li>
        <li>
          <p class="tt">6. 贵司的产品主要销往那个地区？( 必填 ) <span>[ 详细地址 ]</span></p>
          <p>
            <router-link to="/suervey1" target="_blank" class="more">查看详细信息</router-link>
          </p>
        </li>
        <li>
          <p class="tt">7. 贵司的智能产品投产时间 ？( 必填 ) <span>[ 日期选择 ]</span></p>
          <p>
            <router-link to="/suervey1" target="_blank" class="more">查看详细信息</router-link>
          </p>
        </li>-->
      </ul>
    </div>
    <!-- 弹窗 -->
    <el-dialog
        title="多选题百分比计算方法"
        :visible.sync="centerDialogVisible"
        width="27%"
        custom-class="dia_up"
    >
      <div class="calculation">
        <div class="cal_top">
          <p>1、多选题选项百分比＝该选项被选择次数÷有效答卷份数；</p>
          <p>2、含义为选择该选项的人次在所有填写人数中所占的比例。 </p>
        </div>
        <div class="cal_down">
          <p>所以对于多选题百分比相加可能超过百分之一百。 </p>
          <p>举例说明：有10个填写了一道多选题，其中6个人选择了A，5个人选择了B，3个人选择了C。则选择A的比例是60%，选择B的比例的是50%，选择C的比例是30%。3个百分比相加为140%。</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "statistical",
  data() {
    return {
      centerDialogVisible: false, //弹窗按钮
      firmInfo:[
        {
          problem:'您的企业全称叫什么？（必填）',
          choice:'单项填空',
        },
        {
          problem:'您的企业属于哪种企业类型？( 必填 )',
          choice:'单项填空',
          table:[
            {
              options:'有限责任公司',
              subtotal:881,
              percent:'40%'
            },
            {
              options:'有限责任公司',
              subtotal:881,
              percent:'20%'
            },
            {
              options:'有限责任公司',
              subtotal:881,
              percent:'60%'
            },
            {
              options:'有限责任公司',
              subtotal:881,
              percent:'80%'
            },
          ]
        },
        {
          problem:'您的企业全称叫什么？（必填）',
          choice:'多项填空',
        },
        {
          problem:'您的企业全称叫什么？（必填）',
          choice:'单项填空',
          product:{
              name:'有限责任公司 ',
              region:'重庆',
              transport:'车送'
          }
        },
        {
          problem:'您的企业全称叫什么？（必填）',
          choice:'单选题',
          product:{
            name:'有限责任公司 ',
            region:'重庆',
            transport:'车送'
          }
        },
        {
          problem:'您的企业属于哪种企业类型？( 必填 )',
          choice:'单项填空',
          table:[
            {
              options:'有使用',
              subtotal:881,
              percent:'40%'
            },
            {
              options:'没有使用',
              subtotal:881,
              percent:'20%'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.statistical_box{
  width: 100%;
}
.base_info {
  padding: 0 22px;
  li {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dotted rgba(17, 144, 243, .2);
  }
  h3 {
    line-height: 46px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .tt {
    line-height: 32px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    span {
      color: #b8c1c9;
      font-weight: normal;
    }
  }
  .more {
    display: inline-block;
    line-height: 30px;
    height: 30px;
    border: 1px solid #1190f3;
    padding: 0 13px;
    border-radius: 2px;
    color: #1190f3;
  }
  .perWay {
    margin-top: 20px;
  }
  .main_table {
    .filtrate {
      margin-bottom: 20px;
      .down_select {
        float: left;
        margin-right: 10px;
      }
      .btn {
        float: left;
        width: 70px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 2px;
        color: #fff;
        background-color: #1190f3;
        &:hover {
          background-color: #0073cc;
        }
      }
    }
    table {
      width: 100%;
      table-layout: fixed;
      text-align: left;
      border-top: 1px solid #dbe1e5;
      border-right: 1px solid #dbe1e5;
      word-wrap: break-word;
    }
    tr:hover {
      background-color: #f6fbfe;
    }
    th {
      background-color: #f6f7f9;
      line-height: 22px;
      border-bottom: 1px solid #dbe1e5;
      border-left: 1px solid #dbe1e5;
      padding: 8px 16px;
      font-weight: 400;
    }
    td {
      padding: 14px 16px;
      border-bottom: 1px solid #dbe1e5;
      border-left: 1px solid #dbe1e5;
      line-height: 22px;
    }
    .oe1 {
      display: inline-block;
      vertical-align: top;
      max-width: 100%;
    }
    .align_l {
      text-align: left;
    }
    .align_c {
      text-align: center;
    }
    .red {
      color: #fd5451;
    }
    .blue {
      color: #1190f3;
    }
    .checkbox {
      display: block;
      margin: 0 auto;
      width: 14px;
      height: 14px;
      border: 1px solid #dbe1e5;
      background-color: #fff;
      border-radius: 2px;
      cursor: pointer;
      &.on {
        background: url(~@/assets/statistics/public_icon.png) no-repeat 0 0;
        border-color: #1190f3;
      }
    }
    .operate {
      a {
        margin-right: 5%;
      }
    }
    .all_operate {
      margin-top: 20px;
      .btn {
        padding: 2px 0;
        .checkbox {
          margin: 5px 16px;
        }
        a {
          float: left;
          line-height: 26px;
          padding: 0 10px;
          border: 1px solid #b8c1c9;
          border-radius: 2px;
          color: #60696f;
          margin-right: 10px;
          &:hover {
            color: #0073cc;
            border-color: #0073cc;
          }
        }
      }
    }
  }
  .main_table {
    padding: 0;
  }
  .statu {
    position: relative;
    height: 6px;
    padding-right: 12%;
    .bar {
      height: 6px;
      margin-top: 3px;
      border-radius: 4px;
      background: #f6f7f9;
      p {
        border-radius: 4px;
        background: #1190f3;
        height: 6px;
      }
    }
    span {
      position: absolute;
      line-height: 14px;
      top: -3px;
      right: 0;
      width: 10%;
      text-align: left;
    }
  }
}
/*弹窗*/
::v-deep .el-dialog__wrapper .el-dialog.dia_up{
  margin-top: 30vh !important;
}
::v-deep .el-dialog__body{
  padding-top: 7px;
}
.calculation{
  p{
    font-size: 12px;
    line-height: 28px;
    font-family: 'MicrosoftYaHei';
  }
  .cal_top{
    p{
      color: #242D33;
    }
  }
  .cal_down{
    p{
      color: #6C7880;
    }
  }
}
</style>